import "./index.scss";
import arrow from "../../../images/arrow.png";
import SkillsItem from "./SkillsItem";
import development from "../../../images/development.png";
import mentorship from "../../../images/mentorship.png";
import design from "../../../images/design.png";

const Skills = () => {
  const skillsContent = [
    {
      img: development,
      title: "Development",
      description:
        "I like building new ideas or recreating existing tools to improve how they work.",
      label: "Languages I've used:",
      list: [
        "JavaScript (ES6+)",
        "TypeScript",
        "Python",
        "HTML",
        "CSS/Sass",
        "GraphQL",
        "Ruby",
        "Java",
      ],
      sublabel: "Libraries, frameworks, tools & platforms:",
      sublist: [
        "Node.js",
        "Express",
        "React",
        "React Native",
        "Redux",
        "Axios",
        "jQuery",
        "Angular",
        "PostgreSQL",
        "MySQL",
        "MongoDB",
        "Rails",
        "Bootstrap",
        "Bulma",
        "Mocha",
        "Chai",
        "Jest",
        "Vitest",
        "Cypress",
        "Storybook",
        "Git",
        "AWS",
        "OpenAI",
        "Docker",
        "DataDog",
        "Heroku",
        "Netlify",
        "Xcode",
        "Android Studio",
        "WordPress",
      ],
    },
    {
      img: mentorship,
      title: "Mentorship",
      description:
        "I enjoy sharing my passions, teaching others, and breaking learning barriers.",
      label: "Topics I've taught:",
      list: [
        "Python",
        "JavaScript",
        "Data structures",
        "Algorithms",
        "Databases",
        "Recursion",
        "Encapsulation",
        "Testing",
        "OOP",
        "Complexity",
      ],
      sublabel: "Teaching stats:",
      sublist: [
        "4 years experience",
        "15 TAships + 2 Head TAships",
        "10+ exam seminars",
        "30+ mentor sessions",
        "220+ tutorials",
        "500+ students",
      ],
    },
    {
      img: design,
      title: "Creative",
      description:
        "I value creating simple, clean, and intuitive designs with a minimalist aesthetic.",
      label: "Experiences I draw from:",
      list: [
        "Freelancing",
        "Live streaming",
        "Content creation",
        "Personal branding",
        "Project UI/UX",
      ],
      sublabel: "Design & content creation tools:",
      sublist: ["Photoshop", "Illustrator", "Vegas Pro", "OBS Studio"],
    },
  ];
  const skillItems = skillsContent.map((skill, index) => {
    return (
      <SkillsItem
        key={index}
        img={skill.img}
        title={skill.title}
        description={skill.description}
        label={skill.label}
        list={skill.list}
        sublabel={skill.sublabel}
        sublist={skill.sublist}
      />
    );
  });
  return (
    <div className="Skills view">
      <section>
        <div className="section-title">
          <img src={arrow} />
          Skills
          <div className="line" />
        </div>
        <div className="section-content">{skillItems}</div>
      </section>
    </div>
  );
};

export default Skills;
